import {
  MODAL_ADD_ENDERECO_VISIBILITY,
  MODAL_CONFIRM_REMOVE_ENDERECO_VISIBILITY,
} from './actions'

export const modalEndereco = (state, action) => {
  switch (action.type) {
    case MODAL_ADD_ENDERECO_VISIBILITY:
      return { ...state, address_open: action.data }
    case MODAL_CONFIRM_REMOVE_ENDERECO_VISIBILITY:
      return { ...state, confirm_remove_address_open: action.data }
    default:
      return state
  }
}
