import React, { useEffect } from 'react'
import { useAppState } from '../AppContext'
import { fetchLoggedUser } from '../../commons/logged-user/actions'

const componentDidMount = (dispatch, state) => () => {
  dispatch(fetchLoggedUser())
}

function Init() {
  const [state, dispatch] = useAppState()
  useEffect(componentDidMount(dispatch, state), [])

  return !state.logged_user.id && <span className="init__message" />
}

export default Init
