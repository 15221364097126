import { setDependenciesFunctions } from './commons/fetch-api'
import { AppStateStore } from './components/AppContext'
import { loggedUserReducer } from './commons/logged-user/reducer'
import { layoutReducer } from './components/layout/reducer'
import {
  formAlteracaoCadastroReducer,
  modal,
} from './components/dados-cadastrais/reducer'
import { auxStoreReducer } from './commons/auxiliary-store/reducer'
import { changeLoading, changeMessageDelay } from './components/layout/actions'
import { formAlteracaoSenhaReducer } from './components/senha/reducer'
import { formSegurancaReducer } from './components/seguranca/reducer'
import { setTwoFactorOperation } from './components/seguranca/actions'
import {} from './components/seguranca/reducer'
import { INITIAL_STATE } from './commons/constants'
import { modalEndereco } from './components/endereco/reducer'

const Configurations = {
  configFetchApi: () => {
    setDependenciesFunctions({
      changeLoadingState: isLoading => {
        AppStateStore.dispatch(changeLoading(isLoading))
      },
      changeMessageDelay: message => {
        AppStateStore.dispatch(changeMessageDelay(message))
      },
      setTwoFactorOperation: operation => {
        AppStateStore.dispatch(setTwoFactorOperation(operation))
      },
    })
  },
  reducers: (state, action) => {
    if (typeof action === 'function') {
      // Chamada assíncrona das actions que são functions.
      setTimeout(() => {
        action(AppStateStore.dispatch, state)
      }, 0)
      return state
    }

    const newState = {
      aux_store: auxStoreReducer(state.aux_store, action),
      logged_user: loggedUserReducer(state.logged_user, action),
      layout: layoutReducer(state.layout, action),
      form_alter_cadastro: formAlteracaoCadastroReducer(
        state.form_alter_cadastro,
        action
      ),
      form_alter_senha: formAlteracaoSenhaReducer(
        state.form_alter_senha,
        action
      ),
      modal_visibility: modal(state.modal_visibility, action),
      modal_address_visibility: modalEndereco(
        state.modal_address_visibility,
        action
      ),
      form_seguranca: formSegurancaReducer(state.form_seguranca, action),
    }
    return newState
  },
  initialState: INITIAL_STATE,
}

export default Configurations
