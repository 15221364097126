import { API_PATH } from './constants'

let changeLoadingState = () => {}
let changeMessageDelay = () => {}
let setTwoFactorOperation = () => {}

function getJson(response) {
  return response.json().catch(e => {
    return Promise.resolve({}) //Garante que sempre será retornado um JSON (mesmo que seja um JSON vazio)
  })
}

export const setDependenciesFunctions = props => {
  changeLoadingState = props.changeLoadingState
  changeMessageDelay = props.changeMessageDelay
  setTwoFactorOperation = props.setTwoFactorOperation
}

const fetchApi = async (url, options, notJson) => {
  let xsrfToken = document.cookie.replace(
    /(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
    '$1'
  )
  if ((options.method === 'POST' || options.method === 'PUT') && !notJson) {
    options.headers = { 'Content-Type': 'application/json' }
  }
  if (options.headers) {
    options.headers = { ...options.headers, 'X-XSRF-TOKEN': xsrfToken }
  } else if (xsrfToken) {
    options.headers = { 'X-XSRF-TOKEN': xsrfToken }
  }

  changeLoadingState(true)
  const resp = await fetch(url, options)

  let data
  if (resp.status === 200 || resp.status === 201) {
    !notJson ? (data = await getJson(resp)) : (data = await resp.arrayBuffer())
    changeLoadingState(false)
    return data
  }
  changeLoadingState(false)
  data = await getJson(resp)
  if (data.errors) {
    data.errors.map(e => {
      if (e.code === 'REQUEST_SESSION_MUSTEXISTS') {
        sessionStorage.setItem('contextRedirect', window.location.pathname);  //Recurso necessário para voltar à mesma tela após uma reautenticação causada pela perda da sessão ou um acesso direto ao contexto via barra de endereços
        window.location.href = API_PATH.conta.start //Toda vez que este erro é retornado, ocorre uma nova revalidação de sessão chamando o serviço de authorize do sso.
      } else if (
        e.code === 'REQUEST_SESSION_MUSTHAVEUNIQUETOKEN' ||
        e.code === 'REQUEST_SESSION_MUSTHAVEJWSFORMAT' ||
        e.code === 'REQUEST_SESSION_MUSTHAVEJWEFORMAT' ||
        e.code === 'REQUEST_SESSION_MUSTBEENCRYPTEDWITHCORRECTKEY' ||
        e.code === 'REQUEST_SESSION_MUSTHAVEVALIDTOKENCONTENT' ||
        e.code === 'REQUEST_CSRF_MUSTMATCHSESSIONCSRF' ||
        e.code === 'REQUEST_SESSION_SID_MUSTBENOTEMPTY' ||
        e.code === 'REQUEST_SESSION_MUSTHAVEVALIDEXPIRATIONTIME' ||
        e.code === 'REQUEST_SESSION_MUSTHAVEEXPIRATIONTIME' ||
        e.code === 'REQUEST_SESSION_MUSTBENOTEXPIRED' ||
        e.code === 'REQUEST_SESSION_MUSTBENOTREVOKED' ||
        e.code === 'REQUEST_SESSION_ACCESSTOKEN_MUSTBENOTEXPIRED' ||
        e.code === 'ACCESSTOKEN_MUSTBEVALID' ||
        e.code === 'REQUEST_SESSION_ACCESSTOKEN_MUSTBEVALID' ||
        e.code === 'REQUEST_SESSION_IDTOKEN_MUSTBEVALID'
      ) {
        changeMessageDelay({
          type: 'ERROR',
          text: 'Sessão expirada. Aguarde um momento...',
        })
        setTimeout(() => {
          window.location.href = API_PATH.conta.logout
        }, 4000)
      } else if (
          e.code === 'DEVICE_MUSTEXISTS'
        ) {
            setTwoFactorOperation('NO_DEVICES')
          } else {
            changeMessageDelay({ type: 'ERROR', text: e.title })
          }
    })
  }
}

export default fetchApi
