import React, { useEffect } from 'react'
import { changeInfoTela } from '../layout/actions'
import { useAppState } from '../AppContext'
import Card from '../commons/Card'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const componentDidMount = dispatch => () => {
  dispatch(
    changeInfoTela({
      title: 'Privacidade',
      breadcrumbs: [{ label: 'Privacidade' }],
    })
  )
}

function Privacidade() {
  const [state, dispatch] = useAppState()
  const history = useHistory()

  useEffect(componentDidMount(dispatch, state), [])

  return (
    <>
      <span className="page-title">
        <div className="back-icon" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        Privacidade
      </span>

      <span className="page-description">
        Acompanhe o uso dos seus dados relacionados à sua conta gov.br por meio
        das opções abaixo.
      </span>

      <div className="home-cards">
        {/* <Card
          linkTo="#"
          imgSrc="/images/chart-line-medium.png"
          title="Histórico de login"
          description="Visualize todas as vezes em que você logou em um serviço público utilizando a sua conta gov.br"
        /> */}

        <Card
          linkTo={`${process.env.REACT_APP_CONSENTIMENTO_URL}`}
          useLink={false}
          imgSrc="/images/key-solid-medium.png"
          title="Autorizações"
          description="Visualize todos os serviços públicos que você autorizou a utilizar os dados da sua conta gov.br"
        />
      </div>
    </>
  )
}

export default Privacidade
