import React, { useEffect } from 'react'
import { changeInfoTela } from '../layout/actions'
import { useAppState } from '../AppContext'
import Card from '../commons/Card'

const componentDidMount = dispatch => () => {
  dispatch(
    changeInfoTela({
      title: 'Vincular Empresas via e-CNPJ',
      breadcrumbs: [{ label: 'Vincular Empresas via e-CNPJ' }],
    })
  )
}

function Empresas() {
  const [state, dispatch] = useAppState()
  useEffect(componentDidMount(dispatch, state), [])
  return (
    <>
      <span className="page-title">Empresas</span>

      <span className="page-description">
        O módulo de empresas permite que você vincule sua conta à empresa(s).
        Para isso, você deve utilizar o certificado e-CNPJ.
        <br />
        Após a vinculação, você pode gerenciar o cadastramento de outras contas
        (CPFs) que se relacionam com o CNPJ.
      </span>

      <div className="home-cards">
        <Card
          linkTo={`${process.env.REACT_APP_EMPRESAS_URL}`}
          useLink={false}
          imgSrc="/images/key-solid-medium.png"
          title="Vincular Empresas via e-CNPJ"
          description="Clique aqui caso deseje administrar relacionamentos com empresas"
        />
      </div>
    </>
  )
}

export default Empresas
