import React from 'react'
import { useAppState } from '../../AppContext'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  changeFormValues,
  generateCode,
  setModalEmailVisibility,
} from '../actions'
import { changeMessage } from '../../layout/actions'

function Step3(props) {
  const [state, dispatch] = useAppState()
  const form_alter_cadastro = state.form_alter_cadastro

  const enviar_codigo = () => {
    dispatch(changeMessage({}))
    dispatch(
      generateCode('email', () => {
        props.jumpToStep(3)
      })
    )
  }

  const cancel = () => {
    dispatch(changeFormValues({ email: state.logged_user.email, errors: [] }))
    dispatch(changeMessage({}))
    dispatch(setModalEmailVisibility(false))
  }

  return (
    <div className="container-modal">
      <span className="page-title">Atualizar E-mail</span>
      <span className="info">
        Para garantir que esse e-mail seja seu, o GovBR enviará um código de
        verificação.
      </span>
      <label className="">
        {form_alter_cadastro.email}{' '}
        <FontAwesomeIcon icon={faEdit} onClick={() => props.jumpToStep(1)} />
      </label>
      <button className="action-tertiary" onClick={cancel}>
        Cancelar
      </button>
      <button className="action-primary" onClick={enviar_codigo}>
        Enviar código
      </button>
    </div>
  )
}

export default Step3
