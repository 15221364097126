import React from 'react'
import Modal from 'react-modal'
import { useAppState } from '../AppContext'
import { changeFormValues } from '../dados-cadastrais/actions'
import { changeMessage } from '../layout/actions'
import Message from '../layout/Message'
import {
  removeAddress,
  setModalConfimRemoveEnderecoVisibility,
} from './actions'

Modal.setAppElement('#root')

function ModalConfimRemoveAddress() {
  const [state, dispatch] = useAppState()

  const closeModal = () => {
    dispatch(changeFormValues(state.logged_user))
    dispatch(setModalConfimRemoveEnderecoVisibility(false))
    dispatch(changeMessage({}))
  }

  const cancel = () => {
    dispatch(changeFormValues(state.logged_user))
    dispatch(setModalConfimRemoveEnderecoVisibility(false))
    dispatch(changeMessage({}))
  }

  return (
    <Modal
      isOpen={state.modal_address_visibility.confirm_remove_address_open}
      onRequestClose={closeModal}
      contentLabel="Nome"
      className="modal"
    >
      <div className="container-modal flex">
        <Message />
        <span className="page-title">Remover endereço</span>
        <p>
          Confirma a remoção do endereço{' '}
          <b style={{ textTransform: 'CAPITALIZE' }}>
            {state.form_alter_cadastro.removeAddress.typeDescription ||
              state.form_alter_cadastro.removeAddress.idType}
          </b>
          ?
        </p>
      </div>

      <div style={{ textAlign: 'right' }}>
        <button className="action-tertiary" onClick={cancel}>
          Cancelar
        </button>
        <button
          className="action-primary"
          onClick={() => dispatch(removeAddress())}
        >
          Remover
        </button>
      </div>
    </Modal>
  )
}

export default ModalConfimRemoveAddress
