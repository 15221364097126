import React from 'react'
import { Link } from 'react-router-dom'

export default function Card(props) {
  const {
    linkTo,
    onClick,
    warning,
    imgSrc,
    title,
    description,
    useLink = true,
  } = props

  return (
    <>
      {useLink ? (
        <Link to={linkTo} onClick={onClick}>
          <div className="card">
            <img className="card-warn" src={warning} />
            <img className="card-icon" src={imgSrc} />
            <p>{title}</p>
            <p>{description}</p>
          </div>
        </Link>
      ) : (
        <a href={linkTo}>
          <div className="card">
            <img className="card-warn" src={warning} />
            <img className="card-icon" src={imgSrc} />
            <p>{title}</p>
            <p>{description}</p>
          </div>
        </a>
      )}
    </>
  )
}
