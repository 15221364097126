import { CHANGE_FORM_VALUES_ACTION } from './actions';

export const formAlteracaoSenhaReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_FORM_VALUES_ACTION:
      return { ...state, ...action.data };

    default:
      return state;
  }
};
