import fetchApi from '../fetch-api'
import { API_PATH } from '../constants'
import { getPhotoAsBase64 } from '../commons'

export const CHANGE_LOGGED_USER_ACTION = 'USER/CHANGE_LOGGED_USER'

export const changeLoggedUserData = user => ({
  type: CHANGE_LOGGED_USER_ACTION,
  user,
})

export const fetchLoggedUser = () => async dispatch => {
  let user
  let servicosRedirect = `${process.env.REACT_APP_PORTAL_LOGADO_URL}`
  let portalRedirect = `${process.env.REACT_APP_PORTAL_LOGADO_ENABLED}`
  //A verificação da string "undefined" serve para o caso de erro no backend que leva a gravação dessa string.
  if (window.location.pathname === "/" && (portalRedirect === "true" || portalRedirect === "TRUE")) //Redirecionamento para o serviços
    window.location.href = servicosRedirect
  else {
    if (
      sessionStorage.getItem('id') &&
      sessionStorage.getItem('id') !== 'undefined'
    ) {
      user = await fetchApi(
        API_PATH.conta.getConta.replace(':id', sessionStorage.getItem('id')),
        { method: 'GET' }
      )
      dispatch(changeLoggedUserData({ cnpj: sessionStorage.getItem('cnpj') }))
    } else {
      user = await fetchApi(API_PATH.conta.home, { method: 'GET' })
      if (user) {
        if (user.cnpj) {
          dispatch(changeLoggedUserData({ cnpj: user.cnpj }))
          sessionStorage.setItem('cnpj', user.cnpj)
        }
        sessionStorage.setItem('amr', user.amr)
        user = await fetchApi(API_PATH.conta.getConta.replace(':id', user.sub), {
          method: 'GET',
        })
      }
    }

    if (user) {
      user.id = user.sub || user.id //Faz uma simples tradução, pois o sub só eh retornado na chamada ao /api
      sessionStorage.setItem('id', user.id)
      const photo = await getPhotoAsBase64(
        API_PATH.conta.photo.replace(':id', user.id)
      )
      dispatch(changeLoggedUserData({ photoBase64: photo }))
    } else {
      sessionStorage.removeItem('amr');
      sessionStorage.removeItem('cnpj');
      sessionStorage.removeItem('id');
    }

    dispatch(changeLoggedUserData(user))
  }
}

export const logoutUser = () => async dispatch => {
  sessionStorage.removeItem('amr');
  sessionStorage.removeItem('cnpj');
  sessionStorage.removeItem('id');
  window.location.href = API_PATH.conta.logout
}
