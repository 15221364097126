import '../style.css'
import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { changeInfoTela, changeMessage } from '../../layout/actions'
import { useAppState } from '../../AppContext'
import {
  changeFormValues,
  generateOnlineTwoFactorOtp,
  generateOfflineTwoFactorOtp,
  enableOnlineTwoFactorAuthentication,
  enableOfflineTwoFactorAuthentication,
  disableTwoFactorAuthentication,
} from '../actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faCircleInfo,
  faInfo,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import QRCode from 'qrcode.react'
import { isMobileOS } from '../../../utils/responsivity'

const componentDidMount = dispatch => () => {
  dispatch(
    changeInfoTela({
      title: 'Segurança',
      breadcrumbs: [
        { link: '/seguranca', label: 'Segurança' },
        { label: 'Habilitar verificação em duas etapas' },
      ],
    })
  )
  dispatch(generateOnlineTwoFactorOtp())
}

function TwoFactorAuthentication() {
  const [state, dispatch] = useAppState()
  const history = useHistory()
  const form_seguranca = state.form_seguranca
  const urlGooglePlay =
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt&gl=BR'
  const urlAppleStore =
    'https://apps.apple.com/us/app/google-authenticator/id388497605'
  useEffect(componentDidMount(dispatch, state), [])

  const changeValue = e => {
    dispatch(changeFormValues({ otp: e.target.value }))
    dispatch(changeMessage({}))
  }

  const createNewCode = () => {
    dispatch(changeMessage({}))
    dispatch(changeFormValues({ otp: '' }))
    dispatch(generateOnlineTwoFactorOtp())
  }

  const enableTwoFactor = () => {
    dispatch(enableOnlineTwoFactorAuthentication())
  }

  const clickGenerateOfflineTwoFactorOtp = () => {
    dispatch(generateOfflineTwoFactorOtp())
  }

  const clickEnableOfflineTwoFactorOtp = () => {
    dispatch(enableOfflineTwoFactorAuthentication())
  }

  const clickBackToOnlineMultifactorEnabled = () => {
    dispatch(changeFormValues({ operation: 'OTP_INPUT' }))
  }

  const clickDisableTwoFactorAuthentication = () => {
    dispatch(disableTwoFactorAuthentication())
    history.goBack()
  }

  const clickOpenApp = () => {
    dispatch(changeFormValues({ operation: 'SHOW_OFFLINEOTP_ENTERCODE' }))
    window.location.assign(state.form_seguranca.url)
  }

  return (
    <div className="two-factor-authentication">
      {state.form_seguranca.operation === 'OTP_INPUT' && (
        <>
          {!state.logged_user.onlineMultiFactor ? (
            <>
              <span className="page-title">
                <div className="back-icon" onClick={() => history.goBack()}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                Habilitar verificação em duas etapas
              </span>
              <span className="page-description">
                A verificação em duas etapas é um recurso adicional para a
                segurança da sua conta gov.br. <br />A cada novo acesso, você
                recebe um código em seu celular e o utiliza para confirmar que
                está realizando o login.
              </span>

              <div className="two-fa-info">
                <div style={{ flexGrow: '2' }}>
                  <p>
                    <b>Como habilitar a verificação em duas etapas?</b>
                  </p>
                  <ol>
                    <li>
                      Baixe e acesse o aplicativo <b>gov.br</b> no seu celular.{' '}
                    </li>
                    <li>
                      Nesta página, clique no botão <b>“Enviar código”</b>.
                    </li>
                    <li>
                      No campo abaixo, digite o código recebido no aplicativo.
                    </li>
                  </ol>
                </div>
                <img
                  src={`${process.env.REACT_APP_URL_ASSETS}/images/SegundoFator.png`}
                  alt="Verificação em duas etapas"
                />
              </div>

              <Link
                className="action-primary"
                style={{ height: 29 }}
                to="#"
                onClick={createNewCode}
              >
                Enviar código
              </Link>

              <div>
                <input
                  type="text"
                  value={form_seguranca.otp}
                  maxLength="6"
                  onChange={e => changeValue(e)}
                  placeholder="Digite o código"
                />
                <button
                  className="action-primary"
                  type="button"
                  onClick={enableTwoFactor}
                >
                  Habilitar
                </button>
              </div>
            </>
          ) : (
            <>
              <span className="page-title">
                <div className="back-icon" onClick={() => history.goBack()}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                Verificação em duas etapas habilitada
              </span>
              <p style={{ lineHeight: 2 }}>
                Agora todas as vezes que você digitar sua senha <b>gov.br</b>,
                será necessário digitar o código de segurança mostrado no seu
                aplicativo <b>gov.br</b>.
                <br />
                Se por algum motivo você não tiver mais acesso ao seu aplicativo{' '}
                <b>gov.br</b>, será necessário recuperar sua conta usando a
                opção "Esqueci minha senha".
                <br />
                Esta opção está localizada na mesma página onde é digitada a
                senha de acesso.
                <br />
                Na tela "Recuperação de conta", selecione a opção "Validação
                Facial no aplicativo <b>gov.br</b>". Instale o "gov.br" e siga
                os procedimentos na tela. A verificação em duas etapas só será
                desativada por esta opção.
              </p>
              {!state.logged_user.offlineMultiFactor ? (
                <>
                  <p>
                    <strong>Novidade!</strong> Agora é possível habilitar a
                    verificação em duas etapas de forma{' '}
                    <strong>off-line</strong>.
                    <br />
                    Baixe um{' '}
                    <strong>aplicativo de verificação em duas etapas</strong> e
                    leia o QR Code gerado.{' '}
                    <div className="tooltip">
                      <FontAwesomeIcon icon={faCircleInfo} />
                      <span className="tooltiptext">
                        Exemplos de aplicativos: <strong>Free OTP</strong>,{' '}
                        <strong>Authy</strong>,{' '}
                        <strong>Google Authenticator</strong>,{' '}
                        <strong>Microsoft Authenticator</strong>
                      </span>
                    </div>
                  </p>
                  <button
                    className="action-primary"
                    type="button"
                    onClick={clickGenerateOfflineTwoFactorOtp}
                  >
                    Habilitar verificação em duas etapas off-line
                  </button>
                </>
              ) : (
                <>
                  <p>
                    Se você estiver <strong>sem conexão à internet</strong> no
                    seu celular, utilize a opção "Tentar de outra forma" durante
                    o login.
                  </p>
                </>
              )}
              <button
                className="action-secondary"
                type="button"
                onClick={clickDisableTwoFactorAuthentication}
              >
                Desabilitar verificação em duas etapas
              </button>
            </>
          )}
        </>
      )}

      {!isMobileOS() &&
        (state.form_seguranca.operation === 'SHOW_OFFLINEOTP_URL' ||
          state.form_seguranca.operation === 'SHOW_OFFLINEOTP_ENTERCODE') && (
          <>
            <p>
              Abra seu aplicativo de verificação em duas etapas e leia o QR Code
              abaixo
            </p>
            <div style={{ textAlign: 'center' }}>
              <QRCode value={state.form_seguranca.url} size={200} />
              <div>
                <input
                  style={{ width: '12em' }}
                  type="text"
                  value={form_seguranca.otp}
                  maxLength="6"
                  onChange={e => changeValue(e)}
                  placeholder="Digite o código"
                />
                <br />
                <br />
                <div>
                  <button
                    className="action-primary"
                    type="button"
                    onClick={clickEnableOfflineTwoFactorOtp}
                  >
                    Habilitar verificação em duas etapas off-line
                  </button>
                  <button
                    className="action-secondary"
                    type="button"
                    onClick={clickBackToOnlineMultifactorEnabled}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

      {isMobileOS() && (
        <>
          {state.form_seguranca.operation === 'SHOW_OFFLINEOTP_URL' && (
            <>
              <p>
                Clique no botão abaixo para abrir o aplicativo de verificação em
                duas etapas.
              </p>

              <button
                type="button"
                className="action-primary"
                onClick={clickOpenApp}
              >
                Abrir aplicativo
              </button>
            </>
          )}

          {state.form_seguranca.operation === 'SHOW_OFFLINEOTP_ENTERCODE' && (
            <>
              <p>Digite o código fornecido pelo apicativo</p>
              <div>
                <input
                  type="text"
                  value={form_seguranca.otp}
                  maxLength="6"
                  onChange={e => changeValue(e)}
                  placeholder="Digite o código"
                />
                <button
                  className="action-primary"
                  type="button"
                  onClick={clickEnableOfflineTwoFactorOtp}
                >
                  Habilitar verificação em duas etapas off-line
                </button>
                <button
                  className="action-secondary"
                  type="button"
                  onClick={clickBackToOnlineMultifactorEnabled}
                >
                  Voltar
                </button>
              </div>
            </>
          )}
        </>
      )}

      {state.form_seguranca.operation === 'NO_DEVICES' && (
        <>
          <span className="page-title">
            <div className="back-icon" onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            Verificação em duas etapas
          </span>
          <div className="two-fa-info">
            <div style={{ flexGrow: '2' }}>
              <>
                <p>
                  Para habilitar a verificação em duas etapas é necessário
                  instalar o aplicativo <b>gov.br</b> no seu telefone celular ou
                  tablet.
                </p>
                <div className="container-loja-app">
                  <p>
                    Você pode baixar o aplicativo <b>gov.br</b> gratuitamente
                    nas lojas de aplicativos:
                  </p>
                  <div className="container-loja-app-icons">
                    <a href="https://play.google.com/store/apps/details?id=br.gov.meugovbr">
                      <img
                        src={`${process.env.REACT_APP_URL_ASSETS}/images/disponivel-no-google-play.png`}
                        alt="Android"
                      />
                    </a>
                    <a href="https://apps.apple.com/br/app/meu-gov-br/id1506827551">
                      <img
                        src={`${process.env.REACT_APP_URL_ASSETS}/images/disponivel-na-apple-store.png`}
                        alt="iOS"
                      />
                    </a>
                  </div>
                </div>
              </>
            </div>
            <img
              src={`${process.env.REACT_APP_URL_ASSETS}/images/SegundoFator.png`}
              alt="Verificação em duas etapas"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default TwoFactorAuthentication
