import { API_PATH, INITIAL_STATE } from '../../commons/constants'
import fetchApi from '../../commons/fetch-api'
import { changeMessageDelay } from '../layout/actions'
import { changeLoggedUserData } from '../../commons/logged-user/actions'

export const CHANGE_FORM_VALUES_ACTION = 'SEGURANCA/CHANGE_FORM_VALUES'

export const setInitialFormValues = () => {
  return changeFormValues({
    ...INITIAL_STATE.form_seguranca,
  })
}

export const changeFormValues = data => ({
  type: CHANGE_FORM_VALUES_ACTION,
  data,
})

export const setTwoFactorOperation = operation => {
  return changeFormValues({
    operation,
  })
}

export const generateOnlineTwoFactorOtp = () => async (dispatch, state) => {
  const { id } = state.logged_user
  const pathRequest = API_PATH.conta.generateOnlineTwoFactorOtp.replace(
    ':id',
    id
  )

  const retornoObj = await fetchApi(pathRequest, {
    method: 'POST',
    body: JSON.stringify(null),
  })

  if (retornoObj) {
    dispatch(
      changeFormValues({ operation: 'OTP_INPUT', token: retornoObj.token })
    )
  }
}

export const enableOnlineTwoFactorAuthentication = () => async (
  dispatch,
  state
) => {
  const requestBody = {
    otp: state.form_seguranca.otp,
    token: state.form_seguranca.token,
  }
  const retornoObj = await fetchApi(
    API_PATH.conta.enableOnlineTwoFactorAuthentication.replace(
      ':id',
      state.logged_user.id
    ),
    {
      method: 'POST',
      body: JSON.stringify(requestBody),
    }
  )

  if (retornoObj) {
    dispatch(
      changeLoggedUserData({
        onlineMultiFactor: true,
        offlineMultiFactor: false,
      })
    )
    dispatch(
      changeMessageDelay({
        type: 'SUCCESS',
        text: 'Verificação em duas etapas habilitada.',
      })
    )
  }
}

export const generateOfflineTwoFactorOtp = () => async (dispatch, state) => {
  const { id } = state.logged_user
  const pathRequest = API_PATH.conta.generateOfflineTwoFactorOtp.replace(
    ':id',
    id
  )

  const retornoObj = await fetchApi(pathRequest, {
    method: 'POST',
    body: JSON.stringify(null),
  })

  if (retornoObj) {
    dispatch(
      changeFormValues({
        operation: 'SHOW_OFFLINEOTP_URL',
        url: retornoObj.url,
        otp: '',
      })
    )
  }
}

export const enableOfflineTwoFactorAuthentication = () => async (
  dispatch,
  state
) => {
  const requestBody = {
    otp: state.form_seguranca.otp,
    token: state.form_seguranca.token,
  }
  const retornoObj = await fetchApi(
    API_PATH.conta.enableOfflineTwoFactorAuthentication.replace(
      ':id',
      state.logged_user.id
    ),
    {
      method: 'POST',
      body: JSON.stringify(requestBody),
    }
  )
  if (!retornoObj) {
    dispatch(generateOfflineTwoFactorOtp())
    return
  }
  dispatch(
    changeLoggedUserData({
      onlineMultiFactor: true,
      offlineMultiFactor: true,
    })
  )
  dispatch(
    changeMessageDelay({
      type: 'SUCCESS',
      text: 'Verificação em duas etapas off-line habilitada.',
    })
  )
  dispatch(changeFormValues({ operation: 'OTP_INPUT' }))
}

export const disableTwoFactorAuthentication = () => async (dispatch, state) => {
  const retornoObj = await fetchApi(
    API_PATH.conta.disableTwoFactorAuthentication.replace(
      ':id',
      state.logged_user.id
    ),
    {
      method: 'POST',
      body: JSON.stringify(null),
    }
  )

  if (retornoObj) {
    dispatch(
      changeLoggedUserData({
        onlineMultiFactor: false,
        offlineMultiFactor: false,
      })
    )
    dispatch(
      changeMessageDelay({
        type: 'SUCCESS',
        text: 'Verificação em duas etapas desabilitada.',
      })
    )
  }
}
