import fetchApi from './fetch-api'

export const getPhotoAsBase64 = async url => {
  const retornoObj = await fetchApi(url, { method: 'GET' }, true)
  if (retornoObj) {
    const uint = new Uint8Array(retornoObj)
    const img = String.fromCharCode.apply(null, uint)
    const yourByteArrayAsBase64 = btoa( img )
    return 'data:image/jpeg;base64,' + yourByteArrayAsBase64
  }
}
