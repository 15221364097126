import React, { useState } from 'react'
import './header.css'
import Avatar from './Avatar'

function Header() {
  const [respClass, setRespClass] = useState('')

  function menubar() {
    respClass === '' ? setRespClass('responsive') : setRespClass('')
  }

  return (
    <header className={`topnav ${respClass}`}>
      <img
        id="logomarca"
        alt="GovBR"
        src={`${process.env.REACT_APP_URL_ASSETS}/images/govbr.png`}
        onClick={() => (window.location = 'https://www.gov.br')}
      />
      <Avatar />
      <i className="fa fa-adjust" onClick={window.toggleContrast}></i>

      <i id="icon" className="fa fa-ellipsis-v" onClick={menubar}></i>

      <div id="linksgoverno">
        <a href="https://www.gov.br/governodigital/pt-br/acessibilidade-digital">
          Acessibilidade
        </a>
        <a href="http://www4.planalto.gov.br/legislacao/">Legislação</a>
        <a href="https://www.gov.br/acessoainformacao/pt-br">
          Acesso à informação
        </a>
        <a href="https://www.gov.br/pt-br/orgaos-do-governo">Órgaos do Governo</a>
      </div>
    </header>
  )
}

export default Header
