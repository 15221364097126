import React from 'react'
import { useAppState } from '../../AppContext'
import {
  changeFormValues,
  setModalTelefoneVisibility,
  validateCode,
} from '../actions'
import { changeMessage } from '../../layout/actions'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Step4(props) {
  const [state, dispatch] = useAppState()
  const form_alter_cadastro = state.form_alter_cadastro

  const changeValue = e => {
    dispatch(changeMessage({}))
    dispatch(changeFormValues({ errors: [] }))

    const code = window.formatCode(e.target.value)
    dispatch(changeFormValues({ otp: code }))
  }

  const cancel = () => {
    dispatch(
      changeFormValues({
        phoneNumber: state.logged_user.phoneNumber,
        errors: [],
      })
    )
    dispatch(changeMessage({}))
    dispatch(setModalTelefoneVisibility(false))
  }

  return (
    <div className="container-modal">
      <span className="page-title">Atualizar Telefone</span>
      <label>
        <FontAwesomeIcon icon={faPhone} /> {form_alter_cadastro.phoneNumber}
      </label>
      <span>
        Acabamos de enviar um SMS com um código. Digite-o para verificar seu
        novo telefone cadastrado. Não recebeu um SMS?
        <button className="action-tertiary" onClick={() => props.jumpToStep(2)}>
          Tente novamente.
        </button>
      </span>
      <input
        type="text"
        placeholder="Insira o código"
        autoFocus={true}
        value={state.form_alter_cadastro.otp}
        onChange={e => changeValue(e)}
      />
      <button className="action-tertiary" onClick={cancel}>
        Cancelar
      </button>
      <button
        className="action-primary"
        onClick={() => dispatch(validateCode('phone'))}
      >
        Validar código
      </button>
    </div>
  )
}

export default Step4
