export function whoisMobileOS() {
  const userAgentString =
    navigator.userAgent || navigator.vendor || window.opera
  const isAndroid = userAgentString.includes('Android')
  const isiOS =
    userAgentString.includes('iPad') || userAgentString.includes('iPhone')
  return {
    isAndroid: isAndroid,
    isiOS: isiOS,
  }
}

export function isMobileOS() {
  let so = whoisMobileOS()
  return so.isAndroid || so.isiOS
}
