import React from 'react'
import { Link } from 'react-router-dom'
import { useAppState } from '../AppContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

function Breadcrumb() {
  const [{ layout }] = useAppState()

  return (
    <ul className="breadcrumb">
      <li>
        <Link to="/">
          <FontAwesomeIcon icon={faHome} />
        </Link>
      </li>
      {layout.breadcrumbs &&
        layout.breadcrumbs.map((b, i) => (
          <li
            key={b.label}
            className={layout.breadcrumbs.length - 1 === i ? 'is-active' : ''}
          >
            {b.link && <Link to={b.link}>{b.label}</Link>}
            {!b.link && <span>{b.label}</span>}
          </li>
        ))}
    </ul>
  )
}

export default Breadcrumb
