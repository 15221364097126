import React from 'react';
import { useAppState } from '../../AppContext';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setModalTelefoneVisibility } from '../actions';

function Step1(props) {
  const [state, dispatch] = useAppState();
  const form_alter_cadastro = state.form_alter_cadastro;

  return (
    <div className="container-modal">
      <span className="page-title">Atualizar Telefone</span>
      <label>
        <FontAwesomeIcon icon={faPhone} />
        {'  '}
        {window.formatPhone(form_alter_cadastro.phoneNumber)}
      </label>
      <span className="info">
        A atualização alterará seu telefone para alguns serviços no GovBR.
      </span>
      <button
        className="action-tertiary"
        onClick={() => dispatch(setModalTelefoneVisibility(false))}
      >
        Cancelar
      </button>
      <button className="action-primary" onClick={() => props.jumpToStep(1)}>
        Atualizar telefone
      </button>
    </div>
  );
}

export default Step1;
