import './style.css'
import React, { useEffect } from 'react'
import { changeInfoTela } from '../layout/actions'
import { useAppState } from '../AppContext'
import ModalEndereco from './ModalEndereco'
import { useHistory } from 'react-router-dom'
import {
  retrieveAddresses,
  setModalConfimRemoveEnderecoVisibility,
  setModalEnderecoVisibility,
  setAddressAsDefault,
  preencheEnderecoComCEP,
} from './actions'
import ModalConfimRemoveAddress from './ModalConfimRemoveAddress'
import { changeFormValues } from '../dados-cadastrais/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrashAlt,
  faCheck,
  faPlus,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'

const componentDidMount = dispatch => () => {
  dispatch(
    changeInfoTela({
      title: 'Endereço',
      breadcrumbs: [
        { label: 'Dados pessoais', link: '/alteracao_cadastro' },
        { label: 'Endereço' },
      ],
    })
  )
  dispatch(retrieveAddresses())
}

function Endereco() {
  const [state, dispatch] = useAppState()
  const history = useHistory()
  const { addresses } = state.form_alter_cadastro
  addresses.sort((a, b) => b.idType.localeCompare(a.idType))

  useEffect(componentDidMount(dispatch, state), [])

  const openEnderecoModal = () => {
    dispatch(setModalEnderecoVisibility(true))
    let tmpAddress = state.form_alter_cadastro.tmpAddress
    dispatch(
      changeFormValues({
        tmpAddress: { updating: false },
      })
    )
  }

  const openConfirmRemoveEnderecoModal = removeAddress => {
    dispatch(setModalConfimRemoveEnderecoVisibility(true))
    dispatch(changeFormValues({ removeAddress }))
  }

  const alterar = tmpAddress => {
    state.form_alter_cadastro.tmpAddress = tmpAddress
    state.form_alter_cadastro.tmpAddress.updating = true
    if (tmpAddress.zipCode) {
      dispatch(preencheEnderecoComCEP(state.logged_user.id, tmpAddress.zipCode))
    }
    dispatch(changeFormValues({ tmpAddress }))
    dispatch(setModalEnderecoVisibility(true))
  }

  const setAsDefault = idType => {
    dispatch(setAddressAsDefault(idType))
  }

  return (
    <>
      <span className="page-title">
        <div className="back-icon" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        Endereço
      </span>

      {addresses.length < 3 && (
        <a className="link-endereco" onClick={openEnderecoModal}>
          <FontAwesomeIcon icon={faPlus} /> &nbsp; Adicionar endereço
        </a>
      )}
      <div className="container-endereco">
        {addresses &&
          addresses.map(address => (
            <div key={address.idType} className="card-endereco">
              <div className="card-endereco-barra-titulo">
                <>
                  <div className="card-endereco-titulo">
                    {address.typeDescription
                      ? address.typeDescription.toUpperCase()
                      : address.idType.toUpperCase()}
                  </div>
                  <div className="card-endereco-operacoes">
                    {address.defaultAddress && (
                      <div className="tooltip">
                        <FontAwesomeIcon icon={faCheck} />
                        <span className="tooltiptext">Endereço padrão</span>
                      </div>
                    )}
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => alterar(address)}
                    />
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      onClick={() => openConfirmRemoveEnderecoModal(address)}
                    />
                  </div>
                </>
              </div>
              <p>
                <span>
                  {address.address}
                  {address.number && <>, {address.number}</>} -{' '}
                  {address.district}
                </span>
                <br />
                {address.complement && (
                  <>
                    <span>{address.complement}</span>
                    <br />
                  </>
                )}
                <span>
                  {address.city}, {address.state}{' '}
                  {window.cepMask(address.zipCode)}
                </span>
              </p>

              <div style={{ textAlign: 'right' }}>
                {!address.defaultAddress && (
                  <button onClick={() => setAsDefault(address.idType)}>
                    Definir como padrão
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>

      <ModalEndereco />
      <ModalConfimRemoveAddress />
    </>
  )
}

export default Endereco
