import './style.css'
import React from 'react'
import { withRouter } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import Header from './Header'
import Content from './Content'
import Footer from './Footer'
import { useAppState } from '../AppContext'
import Init from '../init/Init'
import Banner from './Banner'

function AppConta(props) {
  const [{ layout, logged_user }] = useAppState()
  const { pathname } = props.location

  return (
    <>
      <Header />
      <div className="container">
        {pathname === '/' && <Banner />}
        {logged_user.id ? <Content /> : <Init />}
        <LoadingOverlay
          active={layout.loading}
          spinner
          text="Aguarde. Estamos processando suas informações"
          styles={{
            overlay: base => ({
              ...base,
              position: 'fixed',
            }),
          }}
        />
      </div>
      <Footer />
    </>
  )
}

export default withRouter(AppConta)
