import React from 'react'
import '../commons/style.css'
import { useAppState } from '../AppContext'
import { saveFotoPerfil } from './actions'
import { changeMessageDelay } from '../layout/actions'

function UploadPhoto() {
  const [state, dispatch] = useAppState()

  const changePhoto = () => {
    const fileInput = document.querySelector('#selectedFile')
    if (fileInput === undefined || fileInput.files.length === 0) {
      return
    }
    fileInput.files[0].size > 1048576
      ? dispatch(
          changeMessageDelay({
            type: 'ERROR',
            text: 'A foto deve ter um tamanho de no máximo 1mb.',
          })
        )
      : dispatch(saveFotoPerfil(fileInput))
  }

  const clickImg = e => {
    const fileInput = document.querySelector('#selectedFile')
    fileInput.click()
  }

  return (
    <div className="card-photo">
      <img
        id="card-avatar"
        style={{ cursor: 'pointer' }}
        src={`${state.logged_user.photoBase64}`}
        alt="Clique para adicionar uma foto"
        onError={e => {
          e.target.src = `${process.env.REACT_APP_URL_ASSETS}/images/foto-upload.png`
        }}
        onClick={clickImg}
      />
      <span className="foto-info" onClick={clickImg}>
        Clique para adicionar ou alterar a foto
      </span>
      <input
        style={{ display: 'none' }}
        type="file"
        id="selectedFile"
        accept="image/*"
        onChange={changePhoto}
      />
    </div>
  )
}

export default UploadPhoto
