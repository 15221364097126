import React from 'react'
import { useAppState } from '../../AppContext'
import {
  changeFormValues,
  setModalEmailVisibility,
  validateCode,
} from '../actions'
import { changeMessage } from '../../layout/actions'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Step4(props) {
  const [state, dispatch] = useAppState()
  const form_alter_cadastro = state.form_alter_cadastro

  const changeValue = e => {
    const code = window.formatCode(e.target.value)
    dispatch(changeMessage({}))
    dispatch(changeFormValues({ errors: [] }))
    dispatch(changeFormValues({ otp: code }))
  }

  const cancel = () => {
    dispatch(changeFormValues({ email: state.logged_user.email, errors: [] }))
    dispatch(changeMessage({}))
    dispatch(setModalEmailVisibility(false))
  }

  return (
    <div className="container-modal">
      <span className="page-title">Atualizar E-mail</span>
      <label>
        <FontAwesomeIcon icon={faEnvelope} /> {form_alter_cadastro.email}
      </label>
      <span>
        Acabamos de enviar um e-mail com um código. Digite-o para verificar seu
        novo e-mail cadastrado. Não recebeu um e-mail?
        <button className="action-tertiary" onClick={() => props.jumpToStep(2)}>
          Tente novamente.
        </button>
      </span>
      <input
        type="text"
        placeholder="Insira o código"
        autoFocus={true}
        value={state.form_alter_cadastro.otp}
        onChange={e => changeValue(e)}
      />
      <button className="action-tertiary" onClick={() => cancel()}>
        Cancelar
      </button>
      <button
        className="action-primary"
        onClick={() => dispatch(validateCode('email'))}
      >
        Validar código
      </button>
    </div>
  )
}

export default Step4
