import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faTimesCircle,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import './style.css'

class PasswordShowHide extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hidden: true,
    }
  }

  toggleShow = () => this.setState({ hidden: !this.state.hidden })

  render() {
    const {
      id,
      placeholder,
      value,
      onChange,
      onFocus,
      onBlur,
      showErro,
      samePassword,
    } = this.props
    const { hidden } = this.state
    return (
      <>
        <input
          id={id}
          type={hidden ? 'password' : 'text'}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <FontAwesomeIcon
          icon={hidden ? faEye : faEyeSlash}
          onClick={this.toggleShow}
          className="password-icon-button"
        />

        {value && samePassword && samePassword !== value && (
          <div className="pwd-strength-tip">
            <div className="error">
              <FontAwesomeIcon icon={faTimesCircle} />{' '}
              <span>As senhas não são iguais.</span>
            </div>
          </div>
        )}

        <div className="pwd-strength-tip">
          {showErro && value && (
            <>
              <div
                className={
                  value.length >= 8 && value.length <= 70 ? 'success' : 'error'
                }
              >
                <FontAwesomeIcon
                  icon={
                    value.length >= 8 && value.length <= 70
                      ? faCheckCircle
                      : faTimesCircle
                  }
                />{' '}
                <span>A senha deve ter mínimo 8 e máximo 70 caracteres.</span>
              </div>
              <div className={value.match('.*[A-Z]+.*') ? 'success' : 'error'}>
                <FontAwesomeIcon
                  icon={
                    value.match('.*[A-Z]+.*') ? faCheckCircle : faTimesCircle
                  }
                />{' '}
                <span>A senha deve ter pelo menos uma letra maiúscula.</span>
              </div>
              <div className={value.match('.*[a-z]+.*') ? 'success' : 'error'}>
                <FontAwesomeIcon
                  icon={
                    value.match('.*[a-z]+.*') ? faCheckCircle : faTimesCircle
                  }
                />{' '}
                <span>A senha deve ter pelo menos uma letra minúscula.</span>
              </div>
              <div className={value.match('.*[^\\w]+.*') ? 'success' : 'error'}>
                <FontAwesomeIcon
                  icon={
                    value.match('.*[^\\w]+.*') ? faCheckCircle : faTimesCircle
                  }
                />{' '}
                <span>
                  A senha deve ter pelo menos um símbolo. Ex: * ! # $ % & + - /
                  : ; = ? @ \ |
                </span>
              </div>
              <div className={value.match('.*[0-9]+.*') ? 'success' : 'error'}>
                <FontAwesomeIcon
                  icon={
                    value.match('.*[0-9]+.*') ? faCheckCircle : faTimesCircle
                  }
                />{' '}
                <span>A senha deve ter pelo menos um número.</span>
              </div>
            </>
          )}
        </div>
      </>
    )
  }
}

PasswordShowHide.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showErro: PropTypes.bool,
  showStrength: PropTypes.bool,
}

PasswordShowHide.defaultProps = {
  placeholder: 'Senha',
  showStrength: false,
  showErro: true,
}

export default PasswordShowHide
