import {
  CHANGE_BREADCRUMB_ACTION,
  CHANGE_LOADING_ACTION,
  CHANGE_MAIN_TITLE_ACTION,
  CHANGE_MESSAGE_ACTION,
  DROPDOWN_MENU_AVATAR,
  DROPDOWN_MENU_SERVICO,
} from './actions'

export const layoutReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_MAIN_TITLE_ACTION:
      return { ...state, title: action.title }

    case CHANGE_LOADING_ACTION:
      return { ...state, loading: action.isLoading }

    case CHANGE_BREADCRUMB_ACTION:
      return { ...state, breadcrumbs: action.breadcrumbs }

    case CHANGE_MESSAGE_ACTION:
      return { ...state, message: action.message }

    case DROPDOWN_MENU_AVATAR:
      return { ...state, avatar: action.data }

    case DROPDOWN_MENU_SERVICO:
      return { ...state, servico: action.data }

    default:
      return state
  }
}
