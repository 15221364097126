import React from 'react'

export default function Banner() {
  return (
    <img
      src={`${process.env.REACT_APP_URL_ASSETS}/images/banner-minha-area.png`}
      width="100%"
      style={{
        marginTop: -11,
        objectFit: 'scale-down',
        padding: '10px 30px 10px 30px',
        alignSelf: 'baseline',
      }}
    />
  )
}
