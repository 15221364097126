import { API_PATH, INITIAL_STATE } from '../../commons/constants'
import fetchApi from '../../commons/fetch-api'
import { changeMessageDelay } from '../layout/actions'

export const CHANGE_FORM_VALUES_ACTION = 'SENHA/CHANGE_FORM_VALUES'

export const changeFormValues = data => ({
  type: CHANGE_FORM_VALUES_ACTION,
  data,
})

export const setInitialFormValues = loggedUser => {
  return changeFormValues({
    ...INITIAL_STATE.form_alter_senha,
    cpf: loggedUser.id,
  })
}

export const updateUserPassword = () => async (dispatch, state) => {
  const { atual, nova, repeticao, cpf } = state.form_alter_senha

  const formData = {
    oldPassword: atual,
    newPassword: nova,
    confirmPassword: repeticao,
  }

  const pathRequest = API_PATH.conta.updatePassword.replace(':id', cpf)

  const retornoObj = await fetchApi(pathRequest, {
    method: 'POST',
    body: JSON.stringify(formData),
  })

  if (retornoObj) {
    dispatch(
      changeMessageDelay({
        type: 'SUCCESS',
        text: 'Senha alterada com sucesso',
      })
    )
    dispatch(setInitialFormValues(state.logged_user))
  }
  window.scrollTo(0, 0)
}
