import React from 'react'
import { useAppState } from '../../AppContext'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  changeFormValues,
  generateCode,
  setModalTelefoneVisibility,
} from '../actions'

function Step3(props) {
  const [state, dispatch] = useAppState()
  const form_alter_cadastro = state.form_alter_cadastro

  const enviar_codigo = () => {
    const phoneNumber = window.formatToOnlyDigits(
      form_alter_cadastro.phoneNumber
    )
    dispatch(changeFormValues({ phoneNumber }))
    dispatch(generateCode('phone', () => props.jumpToStep(3)))
  }

  const cancel = () => {
    dispatch(setModalTelefoneVisibility(false))
    dispatch(
      changeFormValues({
        phoneNumber: state.logged_user.phoneNumber,
        errors: [],
      })
    )
  }

  return (
    <div className="container-modal">
      <span className="page-title">Atualizar Telefone</span>
      <span>
        Para garantir que esse telefone seja seu, o GovBR enviará um código
        deverificação.
      </span>
      <label>
        {form_alter_cadastro.phoneNumber}{' '}
        <FontAwesomeIcon icon={faEdit} onClick={() => props.jumpToStep(1)} />
      </label>
      <button className="action-tertiary" onClick={cancel}>
        Cancelar
      </button>
      <button className="action-primary" onClick={() => enviar_codigo()}>
        Enviar código
      </button>
    </div>
  )
}

export default Step3
