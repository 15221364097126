import React from 'react';
import { useAppState } from '../AppContext';
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Message() {
  const [{ layout }] = useAppState();

  return (
    <>
      {layout.message.type === 'SUCCESS' && (
        <div className="success-message">
          <FontAwesomeIcon icon={faCheckCircle} />{' '}
          <span>{layout.message.text}</span>
        </div>
      )}

      {layout.message.type === 'INFO' && (
        <div className="info-message">
          <FontAwesomeIcon icon={faInfoCircle} />{' '}
          <span>{layout.message.text}</span>
        </div>
      )}

      {layout.message.type === 'ERROR' && (
        <div className="error-message">
          <FontAwesomeIcon icon={faTimesCircle} />{' '}
          <span>{layout.message.text}</span>
        </div>
      )}

      {layout.message.type === 'WARN' && (
        <div className="warn-message">
          <FontAwesomeIcon icon={faExclamationCircle} />{' '}
          <span>{layout.message.text}</span>
        </div>
      )}
    </>
  );
}

export default Message;
