import React from 'react'
import { useAppState } from '../../AppContext'
import {
  changeFormValues,
  setModalEmailVisibility,
  validateForm,
} from '../actions'
import ErrorFieldFeedback from '../../commons/ErrorFieldFeedback'
import { changeMessage, changeMessageDelay } from '../../layout/actions'

function Step2(props) {
  const [state, dispatch] = useAppState()
  const form_alter_cadastro = state.form_alter_cadastro

  const hasError = field => {
    return field
      ? form_alter_cadastro.errors.find(e => e.field === field)
      : form_alter_cadastro.errors.length > 0
  }

  const getErrorMsg = field => {
    const error = form_alter_cadastro.errors.find(e => e.field === field)
    return error && error.msg
  }

  const changeValue = e => {
    dispatch(changeFormValues({ email: e.target.value }))
    dispatch(changeMessage({}))
    dispatch(changeFormValues({ errors: [] }))
  }

  const cancel = () => {
    dispatch(changeFormValues({ email: state.logged_user.email, errors: [] }))
    dispatch(changeMessage({}))
    dispatch(setModalEmailVisibility(false))
  }

  const avancar = () => {
    dispatch(
      validateForm('email', isJump => {
        isJump
          ? props.jumpToStep(2)
          : dispatch(
              changeMessageDelay({
                type: 'ERROR',
                text: 'E-mail deve ser preenchido corretamente',
              })
            )
      })
    )
  }

  return (
    <div className="container-modal">
      <span className="page-title">Atualizar E-mail</span>
      <label htmlFor="input-email">Informe o e-mail que você quer usar:</label>
      <input
        className={`${hasError('email') ? 'is-invalid' : ''}`}
        id="input-email"
        type="text"
        placeholder="E-mail"
        value={form_alter_cadastro.email}
        onChange={e => changeValue(e)}
      />
      <ErrorFieldFeedback
        hasError={hasError('email')}
        message={getErrorMsg('email')}
      />

      <button className="action-tertiary" onClick={cancel}>
        Cancelar
      </button>
      <button className="action-primary" onClick={avancar}>
        Avançar
      </button>
    </div>
  )
}

export default Step2
