import React from 'react'
import { useAppState } from '../../AppContext'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { changeFormValues, setModalEmailVisibility } from '../actions'

function Step1(props) {
  const [state, dispatch] = useAppState()
  const form_alter_cadastro = state.form_alter_cadastro

  const cancel = () => {
    dispatch(changeFormValues(state.logged_user))
    dispatch(setModalEmailVisibility(false))
  }

  return (
    <div className="container-modal">
      <span className="page-title">Atualizar E-mail</span>
      <label>
        <FontAwesomeIcon icon={faEnvelope} /> {form_alter_cadastro.email}
      </label>
      <span className="info">
        A atualização alterará seu e-mail para alguns serviços no GovBR.
      </span>
      <button className="action-tertiary" onClick={cancel}>
        Cancelar
      </button>
      <button className="action-primary" onClick={() => props.jumpToStep(1)}>
        Atualizar e-mail
      </button>
    </div>
  )
}

export default Step1
