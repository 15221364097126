import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

function ErrorFieldFeedback({ hasError, message }) {
  return (
    <>
      {hasError && (
        <div className="error-field-msg" role="alert">
          <FontAwesomeIcon icon={faTimesCircle} /> <span>{message}</span>
        </div>
      )}
    </>
  )
}

export default ErrorFieldFeedback
