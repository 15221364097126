import React, { useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { changeInfoTela } from '../layout/actions'
import { useAppState } from '../AppContext'
import Card from '../commons/Card'
import { disableTwoFactorAuthentication, setInitialFormValues } from './actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const componentDidMount = dispatch => () => {
  dispatch(
    changeInfoTela({
      title: 'Segurança',
      breadcrumbs: [{ label: 'Segurança' }],
    })
  )
  dispatch(setInitialFormValues())
}

function Seguranca() {
  const [state, dispatch] = useAppState()
  const history = useHistory()
  let { url } = useRouteMatch()
  const relLevel = state.logged_user.reliabilityLevel

  useEffect(componentDidMount(dispatch, state), [])

  return (
    <>
      <span className="page-title">
        <div className="back-icon" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        Segurança da conta
      </span>

      <span className="page-description">
        As opções abaixo estão disponíveis para que você gerencie a sua conta e
        a torne cada vez mais segura.
      </span>

      <div className="home-cards">
        <Card
          linkTo={`${process.env.REACT_APP_CATALOGO_URL}`}
          useLink={false}
          warning={relLevel !== 3 && '/images/exclamation-circle-solid.png'}
          imgSrc="/images/chart-line-medium.png"
          title="Aumentar nível da conta"
          description="Conheça as vantagens de aumentar o nível de segurança da sua conta."
        />

        <Card
          linkTo="/seguranca/alteracao_senha"
          imgSrc="/images/key-solid-medium.png"
          title="Alterar senha"
          description="Gerencia sua senha, tornando sua conta mais segura."
        />

        <Card
          linkTo={`${url}/habilitacao-segundo-fator`}
          imgSrc="/images/check-double-solid-medium.png"
          title="Verificação em duas etapas"
          description="Verificação de acesso em duas etapas. Aumente ainda mais a segurança da sua conta."
        />
      </div>
    </>
  )
}

export default Seguranca
