import './style.css'
import React, { useState } from 'react'
import Modal from 'react-modal'
import { useAppState } from '../AppContext'
import { changeFormValues } from '../dados-cadastrais/actions'
import { changeMessage, changeMessageDelay } from '../layout/actions'
import Message from '../layout/Message'
import {
  createOrUpdateAddress,
  preencheEnderecoComCEP,
  setModalEnderecoVisibility,
} from './actions'

Modal.setAppElement('#root')

function ModalEndereco() {
  const [state, dispatch] = useAppState()
  const tmpAddress = state.form_alter_cadastro.tmpAddress
  const idTypes = state.form_alter_cadastro.addresses.map(a => a.idType)
  const zipCodes = state.form_alter_cadastro.addresses.map(a => a.zipCode)
  const [tipo, setTipo] = useState('')

  const closeModal = () => {
    dispatch(changeFormValues(state.logged_user))
    dispatch(changeFormValues({ tmpAddress: {} }))
    dispatch(setModalEnderecoVisibility(false))
    dispatch(changeMessage({}))
    setTipo('')
  }

  const cancel = () => {
    dispatch(changeFormValues(state.logged_user))
    dispatch(setModalEnderecoVisibility(false))
    dispatch(changeMessage({}))
    dispatch(changeFormValues({ tmpAddress: {} }))
    setTipo('')
  }

  const saveAddress = () => {
    if (!tmpAddress.idType) {
      dispatch(
        changeMessageDelay({
          type: 'ERROR',
          text: 'Selecione um tipo de endereço.',
        })
      )
      return
    }
    if (tmpAddress.idType === 'outro' && !tmpAddress.typeDescription) {
      dispatch(
        changeMessageDelay({
          type: 'ERROR',
          text: 'Informe uma descrição para o novo endereço.',
        })
      )
      return
    }
    if (!tmpAddress.zipCode || !isZipCodeValid(tmpAddress.zipCode)) {
      dispatch(
        changeMessageDelay({ type: 'ERROR', text: 'Informe um CEP válido.' })
      )
      return
    }

    dispatch(createOrUpdateAddress())
    dispatch(changeFormValues({ tmpAddress: {} }))
    setTipo('')
  }

  const pesquisaCEP = () => {
    tmpAddress.address = ''
    tmpAddress.complement = ''
    tmpAddress.district = ''
    tmpAddress.state = ''
    tmpAddress.city = ''
    tmpAddress.number = ''
    tmpAddress.token = ''
    if (!isZipCodeValid(tmpAddress.zipCode)) {
      return
    }
    tmpAddress.zipCode = tmpAddress.zipCode.replace(/\D/g, '')
    dispatch(preencheEnderecoComCEP(state.logged_user.id, tmpAddress.zipCode))
  }

  function isZipCodeValid(zipCode) {
    const normalizedZipCode = zipCode && zipCode.replace(/\D/g, '')
    if (!normalizedZipCode || !/^[0-9]{8}$/.test(normalizedZipCode)) {
      return false
    }
    return true
  }

  return (
    <Modal
      isOpen={state.modal_address_visibility.address_open}
      onRequestClose={closeModal}
      contentLabel="Nome"
      className="modal-endereco"
    >
      <div className="container-vertical">
        <Message />
        <span className="page-title">
          {tmpAddress.updating ? <>Editar endereço</> : <>Adicionar endereço</>}
        </span>

        <label htmlFor="addressType">Tipo:</label>

        {idTypes.includes(tmpAddress.idType) ? (
          <b>{tmpAddress.idType.toUpperCase()}</b>
        ) : (
          <select
            name="addressType"
            id="addressType"
            className="select"
            value={tmpAddress.idType}
            onChange={e => {
              setTipo(e.target.value)
              dispatch(
                changeFormValues({
                  tmpAddress: { ...tmpAddress, idType: e.target.value },
                })
              )
            }}
            required
          >
            <option value="">Selecione</option>
            {!idTypes.includes('residencial') && (
              <option value="residencial">Residencial</option>
            )}
            {!idTypes.includes('comercial') && (
              <option value="comercial">Comercial</option>
            )}
            {!idTypes.includes('outro') && <option value="outro">Outro</option>}
          </select>
        )}

        {(tipo === 'outro' || tmpAddress.idType === 'outro') && (
          <input
            id="input-tipo"
            type="text"
            placeholder="Descrição"
            value={tmpAddress.typeDescription}
            maxLength="64"
            required
            onChange={e => {
              dispatch(
                changeFormValues({
                  tmpAddress: {
                    ...tmpAddress,
                    typeDescription: e.target.value,
                  },
                })
              )
            }}
          />
        )}

        <div id="cep">
          <span>CEP</span>
          <a
            href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Não sei meu CEP
          </a>
        </div>
        <input
          id="input-cep"
          type="text"
          placeholder="CEP"
          value={tmpAddress.zipCode}
          maxLength="9"
          onChange={e => {
            const cep = window.cepMask(e.target.value)
            dispatch(
              changeFormValues({
                tmpAddress: { ...tmpAddress, zipCode: cep },
              })
            )
          }}
          onKeyUp={pesquisaCEP}
          required
        />

        <div className="two-fields">
          <div className="twothird">
            <label htmlFor="input-rua">Logradouro (Rua/Avenida)</label>
            <input
              id="input-rua"
              type="text"
              placeholder="Rua"
              value={tmpAddress.address}
              required
              maxLength="128"
              disabled={tmpAddress.hasAddress}
              onChange={e => {
                dispatch(
                  changeFormValues({
                    tmpAddress: { ...tmpAddress, address: e.target.value },
                  })
                )
              }}
            />
          </div>
          <div className="onethird">
            <label htmlFor="input-numero">Número</label>
            <input
              id="input-numero"
              type="text"
              placeholder="Número"
              value={tmpAddress.number}
              maxLength="8"
              onChange={e => {
                dispatch(
                  changeFormValues({
                    tmpAddress: { ...tmpAddress, number: e.target.value },
                  })
                )
              }}
            />
          </div>
        </div>

        <div className="two-fields">
          <div className="twothird">
            <label htmlFor="input-compl">Complemento</label>
            <input
              id="input-compl"
              type="text"
              placeholder="Complemento"
              value={tmpAddress.complement}
              maxLength="128"
              onChange={e => {
                dispatch(
                  changeFormValues({
                    tmpAddress: { ...tmpAddress, complement: e.target.value },
                  })
                )
              }}
            />
          </div>
          <div className="onethird">
            <label htmlFor="input-bairro">Bairro</label>
            <input
              id="input-bairro"
              type="text"
              placeholder="Bairro"
              value={tmpAddress.district}
              maxLength="128"
              required
              disabled={tmpAddress.hasDistrict}
              onChange={e => {
                dispatch(
                  changeFormValues({
                    tmpAddress: { ...tmpAddress, district: e.target.value },
                  })
                )
              }}
            />
          </div>
        </div>

        <div className="two-fields">
          <div className="twothird">
            <label htmlFor="input-cidade">Cidade</label>
            <input
              id="input-cidade"
              type="text"
              placeholder="Cidade"
              value={tmpAddress.city}
              maxLength="128"
              disabled
              required
              onChange={e => {
                dispatch(
                  changeFormValues({
                    tmpAddress: { ...tmpAddress, city: e.target.value },
                  })
                )
              }}
            />
          </div>

          <div className="onethird">
            <label htmlFor="estados-brasil">Estado</label>
            <select
              name="estados-brasil"
              id="estados-brasil"
              className="select"
              value={tmpAddress.state}
              required
              disabled
            >
              <option value="">Estado</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </select>
          </div>
        </div>
      </div>

      <div>
        <div style={{ textAlign: 'right' }}>
          <button className="action-tertiary" onClick={cancel}>
            Cancelar
          </button>

          <button
            className="action-primary"
            type="submit"
            onClick={saveAddress}
          >
            {!idTypes.includes(tmpAddress.idType) ? 'Salvar' : 'Alterar'}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalEndereco
