import React, { useEffect } from 'react'
import { useAppState } from '../../AppContext'
import {
  changeFormValues,
  setModalTelefoneVisibility,
  validateForm,
} from '../actions'
import ErrorFieldFeedback from '../../commons/ErrorFieldFeedback'
import { changeMessage, changeMessageDelay } from '../../layout/actions'

const componentDidMount = (dispatch, state) => () => {
  window.formatPhone(state.form_alter_cadastro.phoneNumber)
}

function Step2(props) {
  const [state, dispatch] = useAppState()
  const form_alter_cadastro = state.form_alter_cadastro
  useEffect(componentDidMount(dispatch, state), [])

  const hasError = field => {
    return field
      ? form_alter_cadastro.errors.find(e => e.field === field)
      : form_alter_cadastro.errors.length > 0
  }

  const getErrorMsg = field => {
    const error = form_alter_cadastro.errors.find(e => e.field === field)
    return error && error.msg
  }

  const changeValue = e => {
    const phone = window.formatPhone(e.target.value)
    dispatch(changeFormValues({ phoneNumber: phone }))
    phone.length === 16 && dispatch(validateForm('phoneNumber'))

    dispatch(changeFormValues({ phoneNumber: e.target.value }))
    dispatch(changeMessage({}))
    dispatch(changeFormValues({ errors: [] }))
  }

  const cancel = () => {
    dispatch(
      changeFormValues({
        phoneNumber: state.logged_user.phoneNumber,
        errors: [],
      })
    )
    dispatch(changeMessage({}))
    dispatch(setModalTelefoneVisibility(false))
  }

  const avancar = () => {
    dispatch(
      validateForm('phoneNumber', isJump => {
        if (isJump) {
          props.jumpToStep(2)
        } else {
          dispatch(
            changeMessageDelay({
              type: 'ERROR',
              text: 'Telefone deve ser preenchido corretamente',
            })
          )
        }
      })
    )
  }

  return (
    <div className="container-modal">
      <span className="page-title">Atualizar Telefone</span>
      <label htmlFor="input-telefone">
        Informe o telefone que você quer usar:
      </label>
      <input
        type="text"
        className={`${hasError('phoneNumber') ? 'is-invalid' : ''}`}
        id="input-phoneNumber"
        placeholder="Celular para receber SMS"
        value={window.formatPhone(form_alter_cadastro.phoneNumber)}
        onChange={e => changeValue(e)}
      />

      <ErrorFieldFeedback
        hasError={hasError('phoneNumber')}
        message={getErrorMsg('phoneNumber')}
      />

      <button className="action-tertiary" onClick={cancel}>
        Cancelar
      </button>
      <button className="action-primary" onClick={avancar}>
        Avançar
      </button>
    </div>
  )
}

export default Step2
