import React from 'react'
import Modal from 'react-modal'
import StepZilla from 'react-stepzilla'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import { useAppState } from '../../AppContext'
import { changeFormValues, setModalTelefoneVisibility } from '../actions'
import { changeMessage } from '../../layout/actions'
import Message from '../../layout/Message'

Modal.setAppElement('#root')

function ModalTelefone() {
  const [state, dispatch] = useAppState()

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  const closeModal = () => {
    dispatch(
      changeFormValues({
        phoneNumber: state.logged_user.phoneNumber,
        errors: [],
      })
    )
    dispatch(changeMessage({}))
    dispatch(setModalTelefoneVisibility(false))
  }

  const steps = [
    { name: 'Step 1', component: <Step1 /> },
    { name: 'Step 2', component: <Step2 /> },
    { name: 'Step 3', component: <Step3 /> },
    { name: 'Step 4', component: <Step4 /> },
  ]

  return (
    <Modal
      isOpen={state.modal_visibility.phone_open}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Telefone"
      className="modal"
    >
      <Message />
      <StepZilla steps={steps} showSteps={false} showNavigation={false} />
    </Modal>
  )
}

export default ModalTelefone
