import React from 'react'
import Modal from 'react-modal'
import { useAppState } from '../../AppContext'
import {
  changeFormValues,
  setModalNomeVisibility,
  updateName,
  validateForm,
} from '../actions'
import ErrorFieldFeedback from '../../commons/ErrorFieldFeedback'
import Message from '../../layout/Message'
import { changeMessage } from '../../layout/actions'

Modal.setAppElement('#root')

function ModalNome() {
  const [state, dispatch] = useAppState()
  const form_alter_cadastro = state.form_alter_cadastro

  const closeModal = () => {
    dispatch(changeFormValues(state.logged_user))
    dispatch(setModalNomeVisibility(false))
    dispatch(changeMessage({}))
  }

  const hasError = field => {
    return field
      ? form_alter_cadastro.errors.find(e => e.field === field)
      : form_alter_cadastro.errors.length > 0
  }

  const getErrorMsg = field => {
    const error = form_alter_cadastro.errors.find(e => e.field === field)
    return error && error.msg
  }

  const cancel = () => {
    dispatch(changeFormValues(state.logged_user))
    dispatch(setModalNomeVisibility(false))
    dispatch(changeMessage({}))
  }

  return (
    <Modal
      isOpen={state.modal_visibility.nome_open}
      onRequestClose={closeModal}
      contentLabel="Nome"
      className="modal"
    >
      <div className="container-modal flex">
        <Message />
        <span className="page-title">Atualizar Nome</span>

        <label htmlFor="input-nome">Informe o nome</label>
        <input
          id="input-nome"
          type="text"
          className={hasError('nome') ? 'is-invalid' : ''}
          placeholder="Informe o nome"
          value={form_alter_cadastro.name}
          onChange={e => {
            const name = window.formatName(e.target.value)
            dispatch(changeFormValues({ name }))
          }}
          onBlur={() => dispatch(validateForm('name'))}
        />

        <ErrorFieldFeedback
          hasError={hasError('nome')}
          message={getErrorMsg('nome')}
        />

        <button className="action-tertiary" onClick={() => cancel()}>
          Cancelar
        </button>
        <button
          className="action-primary"
          onClick={() => dispatch(updateName())}
        >
          Atualizar nome
        </button>
      </div>
    </Modal>
  )
}

export default ModalNome
