import './style.css'
import React, { useEffect } from 'react'
import { useAppState } from '../AppContext'
import { changeInfoTela, changeMessage } from '../layout/actions'
import PasswordShowHide from '../commons/PasswordShowHide'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {
  changeFormValues,
  setInitialFormValues,
  updateUserPassword,
} from './actions'

const componentDidMount = (dispatch, state) => () => {
  dispatch(
    changeInfoTela({
      title: 'Alterar Senha',
      breadcrumbs: [
        { link: '/seguranca', label: 'Segurança' },
        { label: 'Alterar Senha' },
      ],
    })
  )

  dispatch(setInitialFormValues(state.logged_user))
  dispatch(changeMessage({}))
}

const componentDidUpdate = (dispatch, state) => () => {
  state.logged_user.id &&
    !state.form_alter_senha.cpf &&
    dispatch(setInitialFormValues(state.logged_user))
}

function AlteracaoSenha(props) {
  const [state, dispatch] = useAppState()
  const history = useHistory()
  const form_alter_senha = state.form_alter_senha
  const { atual, nova, repeticao, cpf } = form_alter_senha
  useEffect(componentDidMount(dispatch, state), [])

  // Callback usado para caso a tela seja carregada ainda sem o usuário logado,
  // seja possível preencher o forumlario com os dados do usuário assim que ele
  // for alterado: por isso a dependência na função [state.logged_user]
  useEffect(componentDidUpdate(dispatch, state), [state.logged_user])

  return (
    <>
      <span className="page-title">
        <div className="back-icon" onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        Alterar senha
      </span>

      <span className="page-description">
        Escolha uma senha forte para sua conta e não a compartilhe com outras
        pessoas. <br />
        Esta senha poderá ser utilizada para confirmar transações com o governo
        e logar em qualquer site com o acesso gov.br.
      </span>

      <div className="container-cadastro">
        <div className="container-senha">
          <label>CPF</label>
          <label className="cpf">{window.cpfMask(cpf)}</label>

          <label htmlFor="input-senha-atual">Digite a senha atual</label>
          <PasswordShowHide
            id="input-senha-atual"
            placeholder="Digite a senha atual"
            value={atual}
            showErro={false}
            onChange={e =>
              dispatch(changeFormValues({ atual: e.target.value }))
            }
          />

          <label htmlFor="input-nova-senha">Nova senha</label>
          <PasswordShowHide
            id="input-senha-nova"
            type="text"
            value={nova}
            placeholder="Digite a nova senha"
            showStrength={true}
            onChange={e => dispatch(changeFormValues({ nova: e.target.value }))}
          />

          <label htmlFor="input-senha-repetida">Confirme a senha</label>
          <PasswordShowHide
            id="input-senha-repetida"
            placeholder="Repita a senha"
            value={repeticao}
            showErro={false}
            samePassword={nova}
            onChange={e =>
              dispatch(changeFormValues({ repeticao: e.target.value }))
            }
          />
        </div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <button
          className="action-primary"
          type="button"
          onClick={() => dispatch(updateUserPassword())}
        >
          Confirmar
        </button>
        <button
          className="action-tertiary"
          type="button"
          onClick={() => {
            dispatch(setInitialFormValues(state.logged_user))
            dispatch(changeMessage({}))
          }}
        >
          Limpar
        </button>
      </div>
    </>
  )
}

export default AlteracaoSenha
